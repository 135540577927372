<template>
  <div width="100%">
    <el-row type="flex">
      <el-col :span="18">
        <div style="display: flex">
          <h2>{{ __("Screenpop APIs") }}</h2>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tr>
            <th>{{ __("ANI and DNIS example URL") }}</th>
            <td>
              {{ getBackendApiUrl }}<span v-html="scenario_1"></span
              ><span style="padding-left: 5px"
                ><copier :value="getBackendApiUrl + url_scenario_1"></copier
              ></span>
            </td>
          </tr>
          <tr>
            <th>{{ __("DNIS only example URL") }}</th>
            <td>
              {{ getBackendApiUrl }}<span v-html="scenario_2"></span
              ><span style="padding-left: 5px"
                ><copier :value="getBackendApiUrl + url_scenario_2"></copier
              ></span>
            </td>
          </tr>
          <tr>
            <th>{{ __("ANI only example URL") }}</th>
            <td>
              {{ getBackendApiUrl }}<span v-html="scenario_3"></span
              ><span style="padding-left: 5px"
                ><copier :value="getBackendApiUrl + url_scenario_3"></copier
              ></span>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row
      ><h2>{{ __("Parameters") }}</h2></el-row
    >
    <el-row type="flex">
      <el-col>
        <table class="table table-bordered table-striped">
          <tr>
            <th>{{ __("DNIS") }}</th>
            <td>{{ __("OPTIONAL") }}</td>
            <td>
              <span>
                <!-- eslint-disable-next-line -->
                {{ __("This parameter is required if you are using the DNIS pooling method for your screen pop functionality. DNIS is the call center number you are transferring to.") }}
              </span>
              <br /><br />
              <span style="margin-top: 10px;font-weight: bold;"
                >{{ __("Example Value") }}:</span
              ><br />
              <span style="margin-top: 10px;"
                ><ul>
                  <li>370103212</li>
                  <li>+12764775115</li>
                </ul></span
              ><br />
            </td>
          </tr>
          <tr>
            <th>{{ __("ANI") }}</th>
            <td>{{ __("OPTIONAL") }}</td>
            <td>
              <span>
                <!-- eslint-disable-next-line -->
                {{ __("This parameter is required if you are using ANI for your screen pop functionality.") }}
              </span>
              <br /><br />
              <span style="margin-top: 10px;font-weight: bold;"
                >{{ __("Example Value") }}:</span
              ><br />
              <span style="margin-top: 10px;"
                ><ul>
                  <li>370103212</li>
                  <li>+12764775115</li>
                </ul></span
              ><br />
            </td>
          </tr>
          <tr>
            <th>{{ __("FORMAT") }}</th>
            <td>{{ __("OPTIONAL") }}</td>
            <td>
              <span style="margin-right: 10px;">
                <!-- eslint-disable-next-line -->
                {{__("Optional parameter to specify the format of the payload. Default value is") }}
                <span style="font-weight: bold;"> {{ __("HTML") }}</span>
              </span>
              <br /><br />
              <span style="margin-top: 10px;font-weight: bold;"
                >{{ __("Example Value") }}:</span
              ><br />
              <span style="margin-top: 10px;"
                ><ul>
                  <li>HTML</li>
                  <li>JSON</li>
                  <li>XML</li>
                </ul></span
              ><br />
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Copier from "@/components/Copier";
import { appendSector } from "@/utils/url";

export default {
  name: "ScreenpopTransferApiDoc",
  components: {
    Copier
  },
  props: {
    apiKey: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      scenario_1: `<span>/DNIS/<span style="color: var(--theme-color);">DNIS</span>/ANI/<span style="color: var(--theme-color);">ANI</span>/format/<span style="color: var(--theme-color);">FORMAT</span></span>`,
      scenario_2: `<span>/DNIS/<span style="color: var(--theme-color);">DNIS</span>/format/<span style="color: var(--theme-color);">FORMAT</span></span>`,
      scenario_3: `<span>/ANI/<span style="color: var(--theme-color);">ANI</span>/format/<span style="color: var(--theme-color);">FORMAT</span></span>`,
      url_scenario_1: "/DNIS/<DNIS>/ANI/<ANI>/format/<FORMAT>",
      url_scenario_2: "/DNIS/<DNIS>/format/<FORMAT>",
      url_scenario_3: "/ANI/<ANI>/format/<FORMAT>"
    };
  },
  computed: {
    getBackendApiUrl() {
      return appendSector(
        window.location.hostname,
        process.env.VUE_APP_BACKEND_BASE_API,
        "/data/get-screenpop-transfer-data/" + this.apiKey
      );
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
