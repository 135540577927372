<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="false"
      v-loading="nodeLoading"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-form-item
        prop="screenpop_transfer_node.data.transfer_option"
        :label="__('Transfer option')"
      >
        <el-radio
          v-model="nodeToBind.screenpop_transfer_node.data.transfer_option"
          @change="transferOptionChange"
          label="single_phone_number"
          >{{ __("Single phone number") }}</el-radio
        >
        <el-radio
          v-model="nodeToBind.screenpop_transfer_node.data.transfer_option"
          label="dnis_pool"
          >{{ __("DNIS pool") }}</el-radio
        >
      </el-form-item>

      <el-form-item
        :label="__('Single phone number')"
        prop="screenpop_transfer_node.data.property_rules.data.screenpop_transfer_phone_number"
        v-if="showSinglePhoneNumber"
        class="is-required"
      >
        <expression-input
          v-model="transferPhoneNumber"
          :complex-variables="complexVariables"
          :placeholder="__('Enter ANI or use the expression builder')"
          @edit-input="displayTransferPhoneNumberExpressionBuilderModal = true"
        ></expression-input>
      </el-form-item>
      <el-form-item
        :label="__('DNIS pool')"
        prop="screenpop_transfer_node.data.dnis_pool"
        v-if="!showSinglePhoneNumber"
      >
        <el-select
          v-model="nodeToBind.screenpop_transfer_node.data.dnis_pool_id"
          style="width: 100%;"
          default-first-option
          :placeholder="__('Please select a DNIS pool from the list')"
          filterable
          clearable
        >
          <el-option
            v-for="(item, index) in dnispool"
            :label="item.pool_name"
            :value="item.pool_id"
            :key="index"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        :label="__('temp')"
        prop="screenpop_transfer_node.data.property_rules.data"
      >
        <span slot="label" style="margin-right: 10px;"
          >{{ __("Transfer parameters") }}
        </span>

        <template slot="label">
          <el-popover
            placement="top-start"
            style="width: max-content;"
            trigger="click"
          >
            <screenpop-transfer-api-doc
              :api-key="getApiKey"
            ></screenpop-transfer-api-doc>
            <el-link slot="reference" type="primary">{{
              __("API Documentation")
            }}</el-link>
          </el-popover>
        </template>
        <!-- eslint-disable-next-line -->
        {{ __("This is the data you'd like to forward to the call centre. Examples of this could include the customer details from your CRM or previous responses to this conversation. This information sent as key-value pairs. You can also provide a Display Name which is how each parameter will be displayed when the data populated at the call centre.") }}
        <key-value v-model="keyValueData" />
      </el-form-item>
    </el-form>
    <expression-builder-dialog
      v-if="displayTransferPhoneNumberExpressionBuilderModal"
      v-model="transferPhoneNumber"
      :show-expression-builder="
        displayTransferPhoneNumberExpressionBuilderModal
      "
      @input="handleCancelTransferNumberExpressionBuilder"
      @cancel="handleCancelTransferNumberExpressionBuilder"
      @close="handleCancelTransferNumberExpressionBuilder"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import { NODE_TYPES } from "@/constants/nodes";
import _ from "lodash";

import { mapActions, mapState } from "vuex";
import { numberOrVariable } from "@/utils/regex";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import KeyValue from "@/views/build/callflow/components/node-type-forms/components/KeyValue";
import { filterRowsIfEveryKeyValueIsAbsent } from "@/utils/collection";
import ScreenpopTransferApiDoc from "@/views/build/callflow/components/node-type-forms/transfer/ScreenpopTransferApiDoc";

const initScreenpopTransferNode = {
  node_type: NODE_TYPES.SCREENPOP_TRANSFER.NODE_TYPE,
  screenpop_transfer_node: {
    data: {
      key_value_node_data: {
        data: {}
      },
      property_rules: {
        data: {
          screenpop_transfer_phone_number: ""
        }
      },
      transfer_option: "single_phone_number",
      dnis_pool_id: null
    }
  }
};

export default {
  components: {
    ExpressionBuilderDialog,
    ExpressionInput,
    KeyValue,
    ScreenpopTransferApiDoc
  },
  mixins: [BaseNode],

  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(__("Transfer phone number is required"));
      } else if (_.isEmpty(JSON.parse(value))) {
        callback(__("Transfer phone number is required"));
      } else {
        callback();
      }
    };
    const validateDNISPoolId = (rule, value, callback) => {
      if (
        this.getTransferOption === "dnis_pool" &&
        !this.nodeToBind.screenpop_transfer_node.data.dnis_pool_id
      ) {
        callback(__("DNIS pool needs to selected"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        "screenpop_transfer_node.data.property_rules.data.screenpop_transfer_phone_number": [
          {
            validator: validatePhoneNumber,
            trigger: "blur"
          }
        ],
        "screenpop_transfer_node.data.dnis_pool": [
          {
            validator: validateDNISPoolId,
            trigger: "blur"
          }
        ]
      },
      keyValueDataBkup: [],
      newVariableForAudioURL: 0,
      newVariableForAudioVar: 0,
      allowedRegex: numberOrVariable,
      transferTimeoutLimit: _.range(1, 121),
      file_url: "",
      file_name: "",
      isLoading: false,
      createOrUpdateAttachmentStatus: "createFile",
      displayTransferPhoneNumberExpressionBuilderModal: false
    };
  },
  computed: {
    ...mapState("dnispool", {
      dnispool: state => state.dnispool,
      dnispoolLoading: state => state.loading
    }),

    keyValueData: {
      get: function() {
        const { data: key_value_node_data } = _.cloneDeep(
          this.nodeToBind.screenpop_transfer_node.data.key_value_node_data
        );
        return _.isEmpty(key_value_node_data) ? [] : key_value_node_data;
      },

      set: function(val) {
        if (
          // update only if there is a change in word rules
          !_.isEqual(this.keyValueData, val) ||
          _.isEmpty(this.keyValueData)
        ) {
          this.$set(
            this.nodeToBind.screenpop_transfer_node.data.key_value_node_data,
            "data",
            val
          );
        }
      }
    },
    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.screenpop_transfer_node.data.property_rules.data
            .screenpop_transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.screenpop_transfer_node.data.property_rules.data.screenpop_transfer_phone_number = expression;
      }
    },

    showSinglePhoneNumber() {
      return (
        this.nodeToBind.screenpop_transfer_node.data.transfer_option ===
        "single_phone_number"
      );
    },
    getTransferOption() {
      return this.nodeToBind.screenpop_transfer_node.data.transfer_option;
    },
    nodeLoading() {
      return this.dnispoolLoading;
    },
    getApiKey() {
      return _.isEmpty(
        this.nodeToBind.screenpop_transfer_node.data.screenpop_transfer_api_key
      )
        ? "API_KEY"
        : this.nodeToBind.screenpop_transfer_node.data
            .screenpop_transfer_api_key.data.api_key;
    }
  },
  created() {
    if (
      !this.nodeToBind.node_id ||
      _.isEmpty(this.nodeToBind.screenpop_transfer_node)
    ) {
      this.initializeScreenpopTransferNodeData();
    }
    this.getDnisPool();
  },
  methods: {
    ...mapActions("dnispool", {
      getDnisPools: "getDnisPools"
    }),
    initializeScreenpopTransferNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initScreenpopTransferNode)
      );
    },

    getDnisPool() {
      if (!this.dnispool.length) {
        this.getDnisPools({ notShowLoader: false });
      }
    },

    handleError() {
      this.isLoading = false;
      this.$message({
        message: __("Failed to upload the file"),
        type: "error"
      });
    },
    generateUniqueCode() {
      return "file" + Date.now() + Math.random();
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.newFileUploaded = true;
      this.attachment_file_obj.file_path = url.path;
      this.attachment_file_obj.file_url = url.url;
      this.attachment_file_obj.file_name = url.uploaded_file_name;
      this.attachment_file_obj.ac_id = this.selectedAccountId;
      this.attachment_file_obj.unique_code = this.generateUniqueCode();
      this.nodeToBind.screenpop_transfer_node.data.music_on_hold_file_id = -1;
    },

    handleCancel() {
      this.removeUploadedFiles();
      this.$emit("cancel");
    },
    handleDelete() {
      this.resetForm();
    },
    handleCancelTransferNumberExpressionBuilder() {
      this.displayTransferPhoneNumberExpressionBuilderModal = false;
    },

    transferOptionChange() {
      if (
        _.isEmpty(
          this.nodeToBind.screenpop_transfer_node.data.property_rules.data
            .screenpop_transfer_phone_number
        )
      ) {
        this.$set(
          this.nodeToBind.screenpop_transfer_node.data.property_rules.data,
          "screenpop_transfer_phone_number",
          ""
        );
      }
    },

    cleanUpBasedOnTransferOption() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      if (this.getTransferOption === "single_phone_number") {
        this.$delete(
          nodeToCleanUp.screenpop_transfer_node.data,
          "dnis_pool_id"
        );
      } else if (this.getTransferOption === "dnis_pool") {
        this.$delete(
          nodeToCleanUp.screenpop_transfer_node.data,
          "screenpop_transfer_phone_number"
        );
      }
      return nodeToCleanUp;
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);
      const keyValues = filterRowsIfEveryKeyValueIsAbsent(
        _.map(this.keyValueData, keyValue => {
          const { key_name, key_value, display_name } = keyValue;
          return {
            key_name: key_name.toString().trim(),
            key_value: key_value.toString().trim(),
            display_name: display_name.toString().trim()
          };
        }),
        "key_value,key_name"
      );

      this.$set(
        nodeToCleanUp.screenpop_transfer_node.data.key_value_node_data,
        "data",
        keyValues
      );
      return nodeToCleanUp;
    },
    cleanUpNode() {
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.nodeToBind = this.cleanUpBasedOnTransferOption();
      this.createOrEditNode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/node_common.scss";
</style>
