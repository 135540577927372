<template>
  <div class="extractWordsTable">
    <expression-builder-dialog
      :value="expressions(variableToSet)"
      :show-expression-builder="displayExpressionBuilderModal"
      @input="saveExpression($event)(variableToSet)"
      @cancel="handleCancel"
      @close="handleCancel"
    />
    <el-table class="extract-words" fit :data="wordRules" style="width: 100%">
      <el-table-column prop="key_name" :label="__('KEY')">
        <template slot-scope="scope">
          <el-row
            class="goto-variable-configure extract-words-column"
            type="flex"
            justify="start"
          >
            <el-col :class="classes(scope.row, 'variable_name')">
              <el-form-item>
                <el-input v-model="scope.row.key_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>

      <el-table-column prop="key_value" :label="__('VALUE')">
        <template slot-scope="scope">
          <el-row type="flex" class="ruleValue extract-words-column">
            <el-col :class="classes(scope.row, 'key_value')">
              <el-form-item v-if="!useExpressionBuilder">
                <el-input v-model="scope.row.key_value"></el-input>
              </el-form-item>
              <el-form-item v-else>
                <expression-input
                  @edit-input="handleVariableSet(scope.row)"
                  v-model="scope.row.key_value"
                  :complex-variables="complexVariables"
                ></expression-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="display_name" :label="__('DISPLAY NAME')">
        <template slot-scope="scope">
          <el-row
            class="goto-variable-configure extract-words-column"
            type="flex"
            justify="start"
          >
            <el-col :class="classes(scope.row, 'variable_name')">
              <el-form-item>
                <el-input v-model="scope.row.display_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column class-name="cell-item-pointer" width="40px">
        <template slot-scope="scope">
          <span @click="removeWordRule(scope.$index)">
            <i class="el-icon-circle-close"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { getComplexVariables } from "@/api/variables";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";

export default {
  components: { ExpressionInput, ExpressionBuilderDialog },
  props: {
    value: {
      required: true,
      type: Array
    },
    useExpressionBuilder: {
      required: false,
      type: Boolean,
      default: true
    },
    addEmptyRow: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    const word_rule = {
      key_value: "",
      key_name: "",
      display_name: ""
    };

    return {
      variableToSet: {},
      word_rule,
      $word_rules: [{ ...word_rule }],
      complexVariables: []
    };
  },
  computed: {
    ...mapState("canvas", {
      isNodeSubmit: state => state.isNodeSubmit,
      clickedNode: state => state.clickedNode,
      showExpressionBuilder: state => state.showExpressionBuilder
    }),

    descriptionRule() {
      return row => {
        if (row.import_configurable && !row.description) {
          return __("Description cannot be empty");
        } else {
          return "";
        }
      };
    },

    classes() {
      return (row, key) => {
        if (!row.key_value && !row.description && !row.variable_name) {
          return "empty-row empty-val";
        }
        if (key === "description" && !row.import_configurable) {
          return "no-red-highlight";
        }
        if (!row[key]) {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },

    ...mapGetters("expressionbuilder", {
      parameterCountFor: "parameterCountFor",
      isJsonFunction: "isJsonFunction"
    }),

    displayExpressionBuilderModal: {
      get: function() {
        return this.showExpressionBuilder;
      },

      set: function(val) {
        this.setExpressionBuilderModalShow(val);
      }
    },

    expressions: {
      get: function() {
        return row => {
          return row.key_value || "{}";
        };
      }
    },

    wordRules() {
      return this.$data.$word_rules;
    }
  },
  methods: {
    ...mapActions("canvas", {
      setExpressionBuilderModalShow: "setExpressionBuilderModalShow"
    }),

    addNewWordRule() {
      this.addEmptyRow && this.$data.$word_rules.push({ ...this.word_rule });
    },

    removeWordRule(index) {
      this.$data.$word_rules.splice(index, 1);
    },

    handleVariableSet(variable) {
      this.variableToSet = variable;
      this.displayExpressionBuilderModal = true;
    },

    saveExpression({ expression, complexVariables }) {
      return row => {
        this.displayExpressionBuilderModal = false;
        row.key_value = expression;
        this.complexVariables = complexVariables;
      };
    },

    handleCancel() {
      this.displayExpressionBuilderModal = false;
    },

    initializeWordRules(wordRules) {
      if (!this.isNodeSubmit) {
        if (!_.isEmpty(wordRules) && wordRules.length) {
          this.$data.$word_rules = _.cloneDeep(wordRules);
        }
        if (
          !_.some(
            this.$data.$word_rules,
            wordRule => !wordRule.key_value.length
          )
        ) {
          this.addNewWordRule();
          this.$emit("input", _.cloneDeep(this.$data.$word_rules));
        }
      }
    },

    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          this.$set(row, "variable_name", option.label);
          this.$set(row, "variable_id", option.value);
          this.$set(row, "msg", option.msg);
        });
      };
    }
  },

  async created() {
    if (
      this.useExpressionBuilder &&
      _.isEmpty(this.complexVariables) &&
      !_.isEmpty(this.clickedNode)
    ) {
      await getComplexVariables(this.clickedNode.task_id)
        .then(({ data }) => {
          this.complexVariables = data.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "initializeWordRules"
    },
    wordRules: {
      handler: function(wordRules) {
        this.$emit("input", _.cloneDeep(wordRules));
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/expression-builder.scss";
/*.goto-variable-configure {*/
/*  flex-direction: column;*/
/*}*/
/*.goto-variable-configure .el-form-item {*/
/*  margin-bottom: 0;*/
/*}*/

.extractWordsTable ::v-deep .el-table--medium td {
  padding: 15px 0 0 0;
}

.extractWordsTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    /*padding-top: 4px;*/
  }
}

.extractWordsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.extractWordsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

/*.goto-variable-configure ::v-deep .el-form-item__content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*}*/

/*.goto-variable-configure ::v-deep .el-form-item__error {*/
/*  flex-grow: 1;*/
/*  flex-shrink: 0;*/
/*  align-self: center;*/
/*}*/
/*.extract-words ::v-deep .el-table__row {*/
/*  display: flex;*/
/*}*/

.extract-words-column {
  display: flex;
  flex: 1;
}

.extract-words ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.extractWordsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.extractWordsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}
</style>
